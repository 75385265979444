
        @use "sass:math";
        @use "variables" as *;
      
@use '~tailwindcss/base' as *;
@use '~tailwindcss/utilities' as *;

@import 'react-loading-skeleton/dist/skeleton.css';

.vertical-center {
  top: 50%;
  transform: translateY(-50%);
}

.horizontal-center {
  left: 50%;
  transform: translateX(-50%);
}

.directional-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin ribbon-bg($border-color) {
  line-height: 1.5em;
  white-space: nowrap;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    height: 0;
    width: 0;

    border: 0.5em solid transparent;

    width: calc(100% + 1.25em);
    z-index: map-get($zIndex, hidden);
  }

  &::before {
    bottom: 52%;

    border-bottom: 0.8em solid $border-color;
  }

  &::after {
    top: 48%;

    border-top: 0.8em solid $border-color;
  }
}

.ribbon-bg {
  @include ribbon-bg($green-100);

  &.tempo {
    @include ribbon-bg($tempo-blue-100);
  }
}
